import { createAction } from '@reduxjs/toolkit'
import {
  AddTagRequest,
  Devices,
  GetDevicesParameters,
  Templates,
} from 'shared/services'
import { TagType } from 'shared/types'
import { createAsyncThunkWithErrorHandling } from 'shared/utils'
import { MODULE_NAME, SNACKBARS } from '../strings'
import { mapObjectsToTagList } from '../utils'

const templatesService = new Templates()
const devicesService = new Devices()

export const loadTemplateTags = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/loadTemplateTags`,
  payloadCreator: async () => {
    const templates = await templatesService.getTemplates()
    const tags = await templatesService.getTags()
    return mapObjectsToTagList(
      templates.map(template => ({
        ...template,
        tags: template.tags?.map(({ tag }) => tag),
      })),
      tags.map(({ tag, idTag }) => ({ name: tag, id: idTag, items: [] }))
    )
  },
})

export const loadDisplayTags = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/loadDisplayTags`,
  payloadCreator: async (params?: GetDevicesParameters) => {
    const devices = await devicesService.getDisplays(params)
    const tags = await devicesService.getTags()

    return mapObjectsToTagList(
      devices.map(device => ({
        ...device,
        tags: device.tags?.map(({ tag }) => tag),
      })),
      tags.map(({ tag, tagId }) => ({ name: tag, id: tagId, items: [] }))
    )
  },
})

export const addTag = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/addTag`,
  payloadCreator: async (params: { type: TagType; body: AddTagRequest }) =>
    params.type === TagType.Template
      ? templatesService.addTag(params.body)
      : devicesService.addTag(params.body.tag), // TODO: Change when backend will be ready
  ...SNACKBARS.addTag,
})

export const deleteTemplateTag = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/deleteTemplateTag`,
  payloadCreator: async (id: number | string) => templatesService.deleteTag(id),
  ...SNACKBARS.deleteTag,
})

export const deleteDisplayTag = createAsyncThunkWithErrorHandling({
  typePrefix: `${MODULE_NAME}/deleteDisplayTag`,
  payloadCreator: async (id: number) => devicesService.deleteTag(id),
  ...SNACKBARS.deleteTag,
})

export const clearTags = createAction<TagType>(`${MODULE_NAME}/loadTags`)

export const resetAddTag = createAction(`${MODULE_NAME}/resetAddTag`)

export const resetDeleteTemplateTag = createAction(
  `${MODULE_NAME}/resetDeleteTemplateTag`
)

export const resetDeleteDisplayTag = createAction(
  `${MODULE_NAME}/resetDeleteDisplayTag`
)
