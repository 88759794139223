import {
  EcosystemEvent,
  GetConnectionStatusesResponse,
  GetDevicesResponse,
  OTAStatusInfo,
  ProgrammableParam,
} from 'shared/types'
import { convertAxiosErrorToSnackbarData, SnackbarError } from 'shared/utils'
import { Api } from '../api'
import { LayoutDesignerUtils } from '../layoutDesignerUtils'
import {
  GetDevicesParameters,
  GetEcosystemEventsParameters,
  GetDeviceScreenshotResponse,
  RequestDeviceScreenshotResponse,
  ExtendedUpdateDeviceParameters,
  UpdateOTAParams,
  TagsResponse,
} from './Devices.types'

const SUBPATH = '/devices'

const layoutDesignerUtils = new LayoutDesignerUtils()

class Devices extends Api {
  private createProgrammableParams = (
    name: ProgrammableParam,
    templateId?: number,
    url?: string
  ) => {
    switch (name) {
      case ProgrammableParam.Firmware:
        return { parameters: `{"67": "${url}"}` }
      case ProgrammableParam.Template:
        return {
          parameters: `{"67": "5,${url}/devices_endpoints/display_ota_update/${templateId}"}`,
        }
      case ProgrammableParam.DisplayScreenshot:
        return { parameters: '{"21": "1"}' }
      default:
        return null
    }
  }

  getEcosystemEvents = async (params: GetEcosystemEventsParameters) => {
    const { data } = await this.api.get<EcosystemEvent[]>(
      `${SUBPATH}/${params.id}/ecosystemevents`,
      {
        params: {
          ...params,
          id: undefined,
        },
      }
    )

    return data
  }

  getDisplays = async (params?: GetDevicesParameters) => {
    const { data } = await this.api.get<GetDevicesResponse[]>(
      `${SUBPATH}/displays/`,
      { params }
    )

    return data
  }

  getConnectionStatus = async (deviceIds: number[]) => {
    const { data } = await this.api.get<GetConnectionStatusesResponse>(
      `${SUBPATH}/connection_status`,
      {
        params: {
          deviceIds: deviceIds.reduce((ids, id) => `${ids},${id}`, ``).slice(1),
        },
      }
    )

    return data
  }

  updateDisplay = async (
    updateData: Partial<ExtendedUpdateDeviceParameters>,
    id: number
  ) => {
    const { data } = await this.api.put<GetDevicesResponse>(
      `${SUBPATH}/displays/`,
      updateData,
      {
        params: { displayId: id },
      }
    )

    return data
  }

  updateFirmware = async (id: number, firmwareUrl: string) => {
    await this.api.post(`${SUBPATH}/${id}/set_params`, null, {
      params: this.createProgrammableParams(
        ProgrammableParam.Firmware,
        id,
        firmwareUrl
      ),
    })
  }

  requestDeviceScreenshot = async (deviceId: number) => {
    const { data } = await this.api.post<RequestDeviceScreenshotResponse>(
      `${SUBPATH}/${deviceId}/set_params`,
      null,
      {
        params: this.createProgrammableParams(
          ProgrammableParam.DisplayScreenshot
        ),
      }
    )

    return data
  }

  getDeviceScreenshot = async (deviceId: number) => {
    const { data } = await this.api.get<GetDeviceScreenshotResponse>(
      `${SUBPATH}/files`,
      {
        params: { deviceId, category: 'screenshot' },
      }
    )

    return data
  }

  updateOTA = async ({
    templateId,
    deviceId,
    template,
    refreshLastSent,
  }: UpdateOTAParams) => {
    try {
      await layoutDesignerUtils.generateProject(templateId, deviceId)
    } catch (e) {
      const error = convertAxiosErrorToSnackbarData(e)
      throw new SnackbarError(error.message, error.code, 0)
    }
    try {
      const urlToTransform = process.env.REACT_APP_API_URL || '://'
      const { data } = await this.api.post(
        `${SUBPATH}/${deviceId}/set_params`,
        {},
        {
          params: this.createProgrammableParams(
            ProgrammableParam.Template,
            templateId,
            urlToTransform.replaceAll(new RegExp('http?s://', 'ig'), '')
          ),
        }
      )

      const results = await this.updateDisplay(
        { lastSentTemplateId: templateId, lastSentTemplate: template },
        deviceId
      )

      refreshLastSent?.(
        results.lastSentTemplateId,
        results.lastSentTemplateThumbnail
      )

      return data
    } catch (e) {
      const error = convertAxiosErrorToSnackbarData(e)
      throw new SnackbarError(error.message, error.code, 1)
    }
  }

  getOTAStatus = async (deviceId: number) => {
    const { data } = await this.api.get<OTAStatusInfo>(
      `${SUBPATH}/ota_status/`,
      {
        params: {
          deviceId,
        },
      }
    )

    return data
  }

  getTags = async () => {
    const { data } = await this.api.get<TagsResponse[]>(`${SUBPATH}/tags/`)
    return data
  }

  addTag = async (tag: string) => {
    const { data } = await this.api.post(`${SUBPATH}/tags/`, undefined, {
      params: { tag },
    })
    return data
  }

  deleteTag = async (idTag: number) => {
    const { data } = await this.api.delete(`${SUBPATH}/tags/`, {
      params: { idTag },
    })
    return data
  }
}

export default Devices
