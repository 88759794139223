import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import { DisplayManagement } from 'shared/icons'
import { addBasePath } from 'shared/utils'

export const BASE_PATH = '/display-management'

const routes: ModuleRoute[] = addBasePath(BASE_PATH, [
  {
    label: 'navigation.displayManagement',
    icon: DisplayManagement,
    path: '/',
    exact: true,
    component: Loadable({
      component: () => import('./containers/DisplayManagement'),
    }),
  },
  {
    path: '/:id',
    exact: true,
    component: Loadable({
      component: () => import('./containers/DisplayDetails'),
    }),
  },
])

export default routes
